<template>
    <div>
        <div class="nav-calendar-wrapper form-group mt-5">
            <div class="nav-calendar">
                <div class="container-lg">
                    <div class="row align-items-center">
                        <div class="col-6 font-weight-bold text-info">
                            <h5 class="m-0">{{ displayTitle }}</h5>
                        </div>
                        <div class="col-6 text-right">
                            <button class="btn p-0 mr-1 navWeek">
                                <span class="fc-icon fc-icon-chevron-left" @click="() => { navAction(-1) }"></span>
                            </button>
                            <button class="btn p-0 navWeek">
                                <span class="fc-icon fc-icon-chevron-right" @click="() => { navAction(1) }"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <slot :split="displayList"></slot>
    </div>
</template>

<script>
export default {
    name: 'time-splitter',
    props: {
        list: Array,
        splitByMonth: Boolean,
        prop: String,
        title: {
            type: String,
            default: 'Année _Y_'
        },
    },
    mounted() {
        this.init();
    },
    data() {
        return {
            year: null,
            month: 0,
            lists: {},
            isLastYear: false,
            carret: '<',
            displayList: [],
            months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
            days: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi']
        }
    },
    computed: {
        displayTitle() {
            return this.formatDate(`${this.year}-${this.prependZero(this.month + 1)}-01`, this.title)
        },
    },
    watch: {
        list() {
            this.init();
        }
    },
    methods: {
        init() {
            this.list.forEach(item => {
                const date = new Date(item[this.prop])
                const year = date.getFullYear();

                if (!this.lists[year]) {
                    this.lists[year] = this.splitByMonth ? {} : [];
                }

                if (this.splitByMonth) {
                    const month = date.getMonth()

                    if (!this.lists[year][month]) {
                        this.lists[year][month] = [];
                    }

                    if (!this.lists[year][month].find(i => i.idagenda_slot === item.idagenda_slot)) {
                        this.lists[year][month].push(item)
                    }
                } else {
                    this.lists[year].push(item)
                }
            })

            Object.keys(this.lists).forEach(key => {
                Object.keys(this.lists[key]).forEach(key2 => {
                    this.lists[key][key2].sort((a, b) => {
                        if (a.date_slot < b.date_slot) return -1;
                        return 1;
                    })
                })
            })

            this.year = this.$moment(this.list[0]?.date_slot).year();
            this.month = this.$moment(this.list[0]?.date_slot).month();
            this.setDisplayList();
        },
        setDisplayList() {
            if (!this.year || (this.splitByMonth && (this.month === null || this.month === undefined))) this.displayList = []
            this.displayList = (this.splitByMonth ? this.lists[this.year]?.[this.month] : this.lists[this.year]) ?? []
        },
        ObjExtreme(dir, obj) {
            return Math[dir](...Object.keys(obj).map(key => Number(key)))
        },
        prependZero(value) {
            return value > 9 ? value : `0${value}`
        },
        formatDate(date, format) {
            if (!date) return "";
            const d = new Date(date)
            const day = d.getDate()
            const weekDay = d.getDay()
            const month = d.getMonth()
            return format
                .replace('_Y_', d.getFullYear())
                .replace('_m_', month)
                .replace('_M_', this.prependZero(month + 1))
                .replace('_Month_', this.months[month])
                .replace('_D_', this.prependZero(day))
                .replace('_Day_', this.days[weekDay])
        },
        navAction(dir) {
            this.$emit('change-month', dir);
        },

    }
}
</script>